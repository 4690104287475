<template>
  <div class="details">
    <TopBack :title=" $t('home.jtsy')"></TopBack>
    <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('home.noMore')"
        :loading-text="$t('home.loading')"
        :error-text="$t('home.failed')"
        @load="onLoad"
    >
      <div class="order-items" v-for="(item,i) in list" :key="i" >
        <p>{{item.orderNo}}</p>
        <div class="jia">
          <div class="item" >
            <div class="name">{{item.name}}</div>
            <div class="num">{{item.amount}}</div>
          </div>
          +
          <div class="item">
            <div class="name">NO：{{item.turn}}</div>
            <div class="red">{{item.interest}}</div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script setup>
import TopBack from './components/TopBack'
import { onBeforeMount, ref} from 'vue'
import { getUserOrderInterest} from "../api";
const loading = ref(false);
const finished = ref(false);
const pageNo=ref(1)
const total=ref(0)
const list=ref([])
const onLoad = () => {
  // 异步更新数据
  // setTimeout 仅做示例，真实场景中一般为 ajax 请求
  setTimeout(() => {

    // 数据全部加载完成
    if (list.value.length >= total.value) {
      finished.value = true;
    }else {
      pageNo.value+=1
      getHttp()
      loading.value = false;

    }
  }, 1000);
};

onBeforeMount(()=>{
  getHttp()
})
const getHttp=()=>{
  getUserOrderInterest({
    "pageNo":pageNo.value,
    "pageSize":"10",
  }).then(res=>{
    list.value.push(...res.data.orders)
    total.value=res.data.page.totalNum
  })
}

</script>
<style scoped lang="less">

.details{
  padding: 0 17px;
  .order-items{
    background: #232A2E;
    border-radius: 25px;
    padding: 28px 18px;
    margin-bottom: 26px;
    p{
      font-weight: 500;
      font-size: 16px;
      color: #6FFEE8;
      text-align: left;
      margin-top:0;
    }
    .jia{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      font-size: 17px;
      color: #6FFEE8;
    }
    .item{
      width: 40%;
      text-align: center;
      .name{
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        margin-bottom: 10px;
      }
      .num{
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
      }
      .red{
        font-weight: bold;
        font-size: 17px;
        color: #FF5A5A;
      }
    }

  }
}
</style>
